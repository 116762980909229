
import { defineComponent, toRefs } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'PayResult',
  setup() {
    const route = useRoute()
    // 订单号
    const orderNo = route.params.orderNo as string
    return {
      orderNo,
    }
  },
})
